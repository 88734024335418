import React, { useEffect, useRef, useState } from "react";
import SEO from "components/seo";
import ToolFaq from "components/QualityTools/ToolFaq";
import { PreTranslationToolFaqData } from "components/FAQ/config";
import Layout from "components/layout";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import { useLocation } from "@reach/router";
import { fileExtension } from "utils/fileextension";
import UploadIcon from "assets/uploadIcon.svg";
import { navigate } from "gatsby";
import Loader from "components/loader";
import { Languages } from "components/QualityTools/LanguagesData";
import axios from "axios";
import useDebounce from "components/QualityTools/useDebounce";
import { trySampleText } from "utils/sampletext-generator";
import TranslateFromList from "components/QualityTools/TranslateFormList";
import TranslateToList from "components/QualityTools/TranslateToList";
import SourceLanguage from "components/ToolsLanguageDropdown/SourceLangauge";
import TargetLanguage from "components/ToolsLanguageDropdown/TargetLanguage";
import useAutoFocus from "hooks/useAutoFocus";

const Index = () => {
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [shareId, setShareId] = useState(null);
  const location = useLocation();
  const [apiCall, setApiCall] = useState(0);
  const responses = [];
  const [error, setError] = useState(false);
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [sizeLimitError, setSizeLImitError] = useState(false);
  const [file, setFile] = useState(false);
  const [textLoader, setTextLoader] = useState(false);
  const [inputLang, setInputLang] = useState("");
  const debouncedInputText = useDebounce(text);
  const [TransFromOpen, setTransFromOpen] = useState(false);
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [FromSearchInput, setFromSearchInput] = useState("");
  const [sameLanguageError, setSameLanguageError] = useState(false);
  const [divHeight, setDivHeight] = useState(0);
  const [outputLang, setOutputLang] = useState("");
  const [TransToOpen, setTransToOpen] = useState(false);
  const [transtalteToInput, setTranslateToInput] = useState("");
  const [ToSearchInput, setToSearchInput] = useState("");
  const [isEnterText, setIsEnterText] = useState(true);
  const [urlText, setUrlText] = useState("");
  const TranslateFromRef = useRef(null);
  const TranslateFromstyles = {
    display: TransFromOpen ? "block" : "none",
  };
  const TranslateTostyles = {
    display: TransToOpen ? "block" : "none",
  };
  const handleTranslateFromInput = (lang, code) => {
    setInputLang(code);
    setTranslateFromInput(lang);
    setTransFromOpen(!TransFromOpen);
    setFromSearchInput("");
  };

  const handleTranslateToInput = (lang, code) => {
    setOutputLang(code);
    setTranslateToInput(lang);
    setTransToOpen(!TransToOpen);
    setToSearchInput("");
  };
  useAutoFocus("textarea");

  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectSourceLanguage(debouncedInputText);
    }
  }, [debouncedInputText]);
  const detectSourceLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const language = response.data?.data?.language_probability?.code;
      const languageName = Languages.find((lang) => lang?.code === language);

      if (languageName?.name) {
        setInputLang(language);
      }
      //  setTranslateFromInput(lang)
      const languageCode = response.data?.data?.language_probability?.name;
      setTranslateFromInput(languageCode);
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };

  const filteredFromData = Languages.filter((item) => item.code !== outputLang);
  const filteredToData = Languages.filter((item) => item.code !== inputLang);

  const apiEndpoints = [
    "language_detector",
    "identifyTopic",
    "translation_difficulty_checker",
    "contextual_glossary_generator",
    "source_text_checker",
    "style_guide_generator",
    "summarizer",
  ];
  const generateShareId = async () => {
    const { share_id } = await http().get("/api/v2/generate-share-id");
    setShareId(share_id);
  };
  useEffect(() => {
    const query = new URLSearchParams(location.search).get("share-id");
    if (!query) {
      generateShareId();
      return;
    }
    setShareId(query);
  }, []);
  useEffect(() => {
    const wordLength = (text || "")
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;

    setWordCount(wordLength);
  }, [text]);
  const handleTextarea = (e) => {
    let newText = e.target.value;
    const words = newText
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);
    // if (words.length > 1000) {
    //   newText = words.slice(0, 1000).join(" ");
    // }
    setText(newText);
  };
  const getOutputs = async () => {
    setApiCall((preVal) => preVal + 1);
    let payload = {};
    if (outputLang) {
      payload.target_language_code = outputLang;
    }
    const filteredApiEndpoints =
      wordCount < 250 && !urlText
        ? apiEndpoints.filter((tool_name) => tool_name !== "summarizer")
        : apiEndpoints;
    try {
      await Promise.all(
        filteredApiEndpoints.map(async (tool_name) => {
              let postData;
              if (urlText){
                postData = {
                  tool_name,
                  share_id: shareId,
                  user_url: urlText,
                };
              } else {
                postData = {
                  tool_name,
                  user_text: text,
                  share_id: shareId,
                  source_language_code: inputLang,
                  ...payload,
                };
              }
          try {
            const res = await http().post(endpoints.forms.aiTools,postData);
            responses.push(res);
          } catch (error) {
            console.error(`Failed API call for tool_name: ${tool_name}`, error);
            setError(error);
          } finally {
            setError(false);
          }
        })
      );
    } catch (error) {
      console.error("Error during API calls:", error);
    } finally {
      setLoader(false);
    }
  };
  const generateReport = async () => {
    setLoader(true);
    setError("");
    if (apiCall < 3 && responses?.length < 6) {
      await getOutputs();
    }
    if (shareId === undefined && !responses.success) {
      setError("Unable to generate a response. Please try again.");
      return;
    }
    navigate(`/tools/pre-translation/result?share-id=${shareId}`);
  };
  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);
    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      // alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);
      setText(res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };
  const toggleInput = (isTextInput) => {
    setIsEnterText(isTextInput);
    setUrlText("");
    setText("");
  };

  const handleLinkChange = (e) => {
    const text = e.target.value;
    setUrlText(text);
  };

  const clearLink = () => {
    setUrlText("");
  };

  return (
    <Layout>
      <SEO
        title="Pre-Translation Toolkit"
        description="Access the Tomedes Pre-Translation Toolkit to streamline translation preparation with a complete report from multiple AI tools, including topic and language detection, glossary, and context analysis, ensuring high-quality and accurate translations."
        slug="/tools/pre-translation"
      />
      <div className="bg-[#F5F5F5]">
        <div className="pt-7">
          <ToolsBreadcrumb />
        </div>
        <div
          className={`max-w-7xl mx-auto px-4 py-10 lg:py-16 flex flex-col lg:flex-row lg:gap-12 gap-8`}
        >
          <div>
            <h1 className="text-[#5B93FF] mt-0 lg:mt-4 text-center md:text-start text-base font-primary font-semibold leading-6 mb-2.5">
              Pre-Translation Toolkit
            </h1>
            <h2 className="text-[#0A2641] text-center md:text-start font-bold text-2xl lg:text-[32px] lg:leading-[38px] font-primary">
              Avoid Issues and Improve Accuracy with Pre-Translation Tools
            </h2>
            <p className="text-[#424242] text-start text-lg font-opensans leading-[26px] pt-4 lg:block hidden">
              The Tomedes Pre-Translation Dashboard is a one-stop solution for
              translators and businesses, providing a unified report from
              multiple pre-translation tools. It combines insights from the
              Topic Detection Tool, Contextual Glossary, Source Text Checker,
              and more to prepare your content for accurate, culturally nuanced,
              and consistent translations.
            </p>
          </div>
          <div className="flex flex-col lg:items-center gap-3">
            <div className="flex gap-3  justify-center md:justify-start  w-full">
              <button
                onClick={() => toggleInput(true)}
                className={`py-1 px-3 rounded-md font-semibold ${
                  isEnterText
                    ? "text-white bg-[#7AA7FF]"
                    : "text-[#7AA7FF] bg-[#F0F5FF]"
                }`}
              >
                Enter Text
              </button>
              <button
                onClick={() => toggleInput(false)}
                className={`py-1 px-3 rounded-md font-semibold ${
                  isEnterText
                    ? "text-[#7AA7FF] bg-[#F0F5FF]"
                    : "text-white bg-[#7AA7FF]"
                }`}
              >
                Enter URL
              </button>
            </div>
            {isEnterText ? (
              <div className="relative shadow-box  rounded-lg bg-white p-3 ">
                <div className="md:flex justify-between gap-4  border-b border-[#B0B0B0] md:border-none">
                  <TranslateFromList
                    data={{ data: filteredFromData }}
                    TranslateFromRef={TranslateFromRef}
                    TransFromOpen={TransFromOpen}
                    transtalteFromInput={transtalteFromInput}
                    setTransFromOpen={setTransFromOpen}
                    TranslateFromstyles={TranslateFromstyles}
                    FromSearchInput={FromSearchInput}
                    setFromSearchInput={setFromSearchInput}
                    handleTranslateFromInput={handleTranslateFromInput}
                    sameLanguageError={sameLanguageError}
                    divHeight={divHeight}
                  />
                  <TranslateToList
                    data={{ data: filteredToData }}
                    TranslateToRef={TranslateFromRef}
                    TransToOpen={TransToOpen}
                    transtalteToInput={transtalteToInput}
                    setTransToOpen={setTransToOpen}
                    TranslateTostyles={TranslateTostyles}
                    ToSearchInput={ToSearchInput}
                    setToSearchInput={setToSearchInput}
                    handleTranslateToInput={handleTranslateToInput}
                    sameLanguageError={sameLanguageError}
                    divHeight={divHeight}
                  />
                </div>
                <div className="flex gap-3  lg:w-[576px] min-h-[268px] w-full md:mt-0 mt-5">
                  {fileUploadLoading ? (
                    <FileLoadingInput file={file} />
                  ) : sizeLimitError && !fileUploadLoading ? (
                    <ReUploadFile
                      file={file}
                      setFile={setFile}
                      setSizeLImitError={setSizeLImitError}
                      uploadFile={uploadFile}
                    />
                  ) : uploadError && !fileUploadLoading ? (
                    <UnsupportedFile
                      file={file}
                      setSizeLImitError={setSizeLImitError}
                      setFile={setFile}
                      uploadFile={uploadFile}
                      setFileUploadError={setUploadError}
                    />
                  ) : (
                    <div className="relative  w-full h-full">
                      <textarea
                        dir={
                          inputLang === "fa" ||
                          inputLang === "he" ||
                          inputLang === "ar"
                            ? "rtl"
                            : "ltr"
                        }
                        autoFocus
                        value={text}
                        placeholder={textLoader ? "" : "Type your text here or"}
                        name="message"
                        id="message"
                        className="tool min-h-[260px] w-full placeholder-[#B4B4B4] text-black font-primary bg-transparent text-[15px] overscroll-y-none resize-none border-none outline-none block "
                        onChange={(e) => handleTextarea(e)}
                      />
                      {textLoader && (
                        <div className="absolute top-[0px] left-[14px] text-[#B4B4B4] italic">
                          Adding sample text...
                        </div>
                      )}
                      {!text && !textLoader && (
                        <button
                          className="absolute top-[0px] left-[172px] underline italic text-[#5B93FF] cursor-pointer pointer-events-auto"
                          onClick={() => trySampleText(setText, setTextLoader)}
                        >
                          Try Sample Text
                        </button>
                      )}
                    </div>
                  )}
                  {text && (
                    <svg
                      onClick={() => setText("")}
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M11.7472 10.5355C11.9081 10.6964 11.9986 10.9148 11.9986 11.1424C11.9986 11.37 11.9081 11.5884 11.7472 11.7493C11.5862 11.9103 11.3679 12.0007 11.1403 12.0007C10.9126 12.0007 10.6943 11.9103 10.5333 11.7493L6 7.21455L1.46523 11.7479C1.30427 11.9089 1.08595 11.9993 0.858315 11.9993C0.630676 11.9993 0.41236 11.9089 0.251395 11.7479C0.0904294 11.5869 2.39857e-09 11.3686 0 11.141C-2.39857e-09 10.9133 0.0904294 10.695 0.251395 10.5341L4.78616 6.00071L0.252822 1.46595C0.0918573 1.30498 0.00142808 1.08667 0.00142808 0.859029C0.00142808 0.63139 0.0918573 0.413074 0.252822 0.252109C0.413788 0.0911433 0.632104 0.000713906 0.859743 0.000713904C1.08738 0.000713902 1.3057 0.0911433 1.46666 0.252109L6 4.78687L10.5348 0.251394C10.6957 0.0904292 10.914 -3.79247e-09 11.1417 0C11.3693 3.79247e-09 11.5876 0.0904292 11.7486 0.251394C11.9096 0.41236 12 0.630675 12 0.858315C12 1.08595 11.9096 1.30427 11.7486 1.46524L7.21384 6.00071L11.7472 10.5355Z"
                        fill="#69AAE8"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex justify-between items-center mt-1.5">
                  <div className="flex justify-between items-center py-4">
                    {text ? (
                      <p className="text-right mt-2 text-sm text-[#9C9C9C]">
                        {wordCount} words
                      </p>
                    ) : (
                      <>
                        <label
                          htmlFor={`uploadFile `}
                          className="flex gap-2 cursor-pointer"
                        >
                          <img src={UploadIcon} alt="upload_icon" />
                          <span>Upload File</span>
                        </label>
                        <input
                          type="file"
                          className="hidden"
                          id={`uploadFile `}
                          onChange={uploadFile}
                        />
                      </>
                    )}
                  </div>
                  {error && !loader && (
                    <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
                      {error}
                    </span>
                  )}
                  <button
                    disabled={!text || !inputLang}
                    onClick={generateReport}
                    type="submit"
                    className={` ${
                      !text || !inputLang
                        ? "bg-[#E8E8E8] text-[#858585] cursor-not-allowed "
                        : "bg-lightBlue text-white"
                    } text-base font-semibold  font-opensans text-center rounded-md block px-6 py-3 w-[175px]`}
                  >
                    {loader ? <Loader /> : "Generate report"}
                  </button>
                </div>
              </div>
            ) : (
              <div className="lg:w-[576px] min-h-[268px] w-full md:mt-0  bg-white">
                <textarea
                  name="url"
                  value={urlText}
                  placeholder="Paste URL here"
                  className={`min-h-[250px]  font-opensans placeholder-[#858585] text-[#1463FF] leading-[26px] py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full`}
                  onChange={handleLinkChange}
                  readOnly={loader}
                />
                <div className="flex justify-between items-center p-4 mt-8">
                  <span></span>
                  <div className="flex gap-2">
                    <button
                      type="button"
                      className={` ${
                        urlText.length > 0
                          ? "text-[#0A2641] border border-[#0A2641] cursor-pointer"
                          : "border border-[#B0B0B0] text-[#B0B0B0]"
                      } flex gap-2 bg-white px-4 py-2 rounded-md`}
                      onClick={clearLink}
                      disabled={urlText.length === 0}
                    >
                      Clear link
                    </button>
                    <button
                      type="button"
                      onClick={generateReport}
                      disabled={!urlText}
                      className={`bg-[#5B93FF] ${
                        !urlText ? " cursor-not-allowed" : ""
                      } text-white px-4 py-2 rounded-md`}
                    >
                      {loader ? <Loader /> : "Generate report"}
                    </button>
                  </div>
                </div>
              </div>
            )}
            <p className="text-[#6E6E6E] font-opensans text-[12px] text-center">
              Assisted by AI tools. Inaccuracies may occur.
            </p>
            <p className="text-[#424242] text-start text-lg font-opensans leading-[26px] mt-[18px] lg:hidden block">
              The Tomedes Pre-Translation Dashboard is a one-stop solution for
              translators and businesses, providing a unified report from
              multiple pre-translation tools. It combines insights from the
              Topic Detection Tool, Contextual Glossary, Source Text Checker,
              and more to prepare your content for accurate, culturally nuanced,
              and consistent translations.
            </p>
          </div>
        </div>
      </div>
      <ToolFaq QaToolFaqData={PreTranslationToolFaqData} />
    </Layout>
  );
};

export default Index;
